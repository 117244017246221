import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VCard,{staticClass:"pa-4"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.entity.phone))]),_c(VCardText,[_c('UserTabLinks'),_c(VRow,{staticClass:"pl-3 pb-3"}),_c(VRow,{staticClass:"form-group",attrs:{"form":""}},[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Телефон")]),_c(VCol,{attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.entity.phone))])],1),(_vm.isAdmin)?_c(VRow,{staticClass:"form-group",attrs:{"form":""}},[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Админ")]),_c(VCol,{attrs:{"sm":"9"}},[_c('span',{class:`true-false-label ${_vm.getBadge(_vm.entity.admin)}`},[_vm._v("  "+_vm._s(_vm.entity.admin ? 'Да' : 'Нет')+"  ")])])],1):_vm._e(),(_vm.isAdmin)?_c(VRow,{staticClass:"form-group",attrs:{"form":""}},[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Партнер")]),_c(VCol,{attrs:{"sm":"9"}},[_c('span',{class:`true-false-label ${_vm.getBadge(_vm.entity.partner)}`},[_vm._v("  "+_vm._s(_vm.entity.partner ? 'Да' : 'Нет')+"  ")])])],1):_vm._e(),_c(VRow,{staticClass:"form-group",attrs:{"form":""}},[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Имя")]),_c(VCol,{attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.entity.firstname))])],1),_c(VRow,{staticClass:"form-group",attrs:{"form":""}},[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Фамилия")]),_c(VCol,{attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.entity.lastname))])],1),(_vm.isAdmin)?_c(VRow,{staticClass:"form-group",attrs:{"form":""}},[_c(VCol,{attrs:{"sm":"3"}},[_vm._v("Активен")]),_c(VCol,{attrs:{"sm":"9"}},[_c('span',{class:`true-false-label ${_vm.getBadge(_vm.entity.enabled)}`},[_vm._v("  "+_vm._s(_vm.entity.enabled ? 'Да' : 'Нет')+"  ")])])],1):_vm._e()],1),_c(VCardActions,{staticClass:"pa-4 d-flex justify-space-between"},[_c('div',[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"light"},on:{"click":_vm.goBack}},[_vm._v("К списку")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }